import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DomainDetail = _resolveComponent("DomainDetail")!
  const _component_ToolbarWithSidenav = _resolveComponent("ToolbarWithSidenav")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ToolbarWithSidenav, null, {
          default: _withCtx(() => [
            _createVNode(_component_DomainDetail)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}